import GET_ALL_COUNTRIES from '@/graphql/countries/query/getAllCountries.gql'
import GET_ALL_COUNTRIES_BY_CODE from '@/graphql/countries/query/getAllCountriesByCode.gql'

const countries = ref([])
const countriesByCode = ref([])
const countriesFilter = ref([])
const firstTime = ref(true)
const firstTime2 = ref(true)
const firstTime3 = ref(true)
const key = ref(0)
const key2 = ref(0)
const key3 = ref(0)

export function countriesAutocomplete(params: any) {
  const apolloQuery = GET_ALL_COUNTRIES
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 300,
    page: 1
  }

  if (!params.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export function countriesAutocompleteByCode(params: any) {
  const apolloQuery = GET_ALL_COUNTRIES_BY_CODE
  const variables = {
    search: params.search,
    orderBy: [
      {
        column: 'NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 500,
    page: 1
  }

  if (!params.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchCountriesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await countriesAutocomplete({ search: queryString })
    cb(results?.getAllCountries?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCountriesCb')
  }
}

export function getSearchCountriesByCode() {
  if (firstTime.value === true) {
    firstTime.value = false
    searchCountriesByCode('')
  }
  return countriesByCode?.value ?? []
}

export async function searchCountriesByCode(queryString: any, refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await countriesAutocompleteByCode({ search: queryString })
    const remoteCountries = results?.getAllCountries?.data?.map((i: any) => {
      return {
        label: i.name,
        code: i.code,
        value: i.uuid
      }
    }) ?? []
    if (refresh) {
      key2.value = 0
    }
    if (key.value === 0) {
      key2.value = setCustomKeyVariable()
    }
    countriesByCode.value = [...remoteCountries]
    firstTime.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCountriesByCode')
  }
}

export function getSearchCountries(t: any) {
  if (firstTime2.value === true) {
    firstTime2.value = false
    searchCountries('')
  }
  return countries?.value?.map(i => {
    return {
      label: t('countries.' + i.label
        .replaceAll(' ', '_')
        .replaceAll('/', '_')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '_')
        .replaceAll('\'', '')
        .replaceAll(',', '')
        .replaceAll('.', '')
        .toLowerCase()),
      code: i.code,
      value: i.value
    }
  }) ?? []
}

export async function searchCountries(queryString: any, refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await countriesAutocomplete({ search: queryString })
    const remoteCountries = results?.getAllCountries?.data?.map((i: any) => {
      return {
        label: i.name,
        code: i.code,
        value: i.uuid
      }
    }) ?? []
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    countries.value = [...remoteCountries]
    firstTime2.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCountries')
  }
}

export function getSearchCountriesFilters() {
  if (firstTime3.value === true) {
    firstTime3.value = false
    searchCountriesFilters('')
  }
  return countriesFilter?.value ?? []
}

export async function searchCountriesFilters(queryString: any, refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await countriesAutocomplete({ search: queryString })
    const remoteCountries = results?.getAllCountries?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []
    if (refresh) {
      key3.value = 0
    }
    if (key3.value === 0) {
      key3.value = setCustomKeyVariable()
    }
    countriesFilter.value = [...remoteCountries]
    firstTime3.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCountriesFilters')
  }
}

export function getCountryKey() {
  return key.value
}

export function getCountryByCodeKey() {
  return key2.value
}

export function getCountryFiltersKey() {
  return key3.value
}

export function initialCountries() {
  key.value = 0
  key2.value = 0
  key3.value = 0
  countries.value = []
  countriesByCode.value = []
  countriesFilter.value = []
  firstTime.value = true
  firstTime2.value = true
  firstTime3.value = true
}